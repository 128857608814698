.vcenter-admin-box {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.edit-nav-margin-top{
    margin-top: 30px;
}

.no-padding{
    padding: 0px;
}

.modal-content .modal-header.delete{
    background-color: rgba(244, 67,54, 0.8);
}


.modal-dialog {
  position:absolute;
  top:50% !important;
  transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  margin:auto 35%;
  width: 30%;
  height: 30%;
}

.material-icons.red { color: #f44336; }
.material-icons.green { color: green; }

.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

#textAreaDescr {
  height: 200px;
}

#map-outer {  height: 440px; 
                    padding: 20px; 
                    border: 2px solid #CCC; 
                    margin-bottom: 20px; 
                    background-color:#FFF }
      #map-container { height: 400px }
      @media all and (max-width: 991px) {
        #map-outer  { height: 650px }
        }